<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="table1.headers"
          :items="table1.items"
          :loading="table1.loading"
          hide-default-footer
          :page.sync="pageTable1"
          :items-per-page="table1.itemsPerPage"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="font-weight-bold">Transaksi Barang</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="table1.dialogCU" max-width="900px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialogTable1()">
                    Tambah
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Pembelian Barang</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="1" sm="1" md="2">
                          <v-btn color="primary" small @click="table1.dialogPilihanggota = true"> Pilih Anggota </v-btn>
                          <input type="hidden" v-model="table1.itemEdit.id_anggota" />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            v-model="table1.itemEdit.kode_anggota"
                            label="Kode Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            dense
                            readonly
                            outlined
                            hide-details
                            v-model="table1.itemEdit.nama_anggota"
                            label="Nama Anggota *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            v-model="table1.itemEdit.jenis_upah_anggota"
                            label="Jenis Upah *"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="1" sm="1" md="2">
                          <v-btn color="primary" small @click="table1.dialogPilihBarang = true"> Pilih Barang </v-btn>
                          <input type="hidden" v-model="table1.itemEdit.id_barang" />
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            v-model="table1.itemEdit.nama_barang"
                            label="Nama Barang *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            v-model="table1.itemEdit.detail_barang"
                            label="Detail Barang *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="3">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            v-model="table1.itemEdit.harga_temp"
                            label="Harga Jual *"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red" class="ml-4">
                            {{ formatMoney(table1.itemEdit.harga_temp) }}
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="3">
                          <v-text-field
                            type="number"
                            v-model="table1.itemEdit.qty"
                            dense
                            outlined
                            hide-details
                            label="Jumlah Beli *"
                            @change="hitungTotal()"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field
                            readonly
                            outlined
                            dense
                            hide-details
                            v-model="table1.itemEdit.harga"
                            label="Total Harga *"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red" class="ml-4">
                            {{ formatMoney(table1.itemEdit.harga) }}
                          </span>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <v-select
                            label="Jenis Bayar *"
                            :items="table1.jenis_bayars"
                            hide-details="auto"
                            outlined
                            dense
                            v-model="table1.itemEdit.jenis_bayar"
                            @change="cekJenisBayar()"
                          ></v-select>
                        </v-col>
                        <v-col cols="2" md="2" v-if="table1.itemEdit.jenis_bayar == 'cicil'">
                          <v-btn class="primary" small @click="getCicilan()">Cek</v-btn>
                        </v-col>
                      </v-row>
                      <v-row v-if="Object.keys(table1.parameterCicilan).length !== 0">
                        <v-col cols="12">
                          <v-simple-table dense style="width: 100%">
                            <tbody>
                              <tr>
                                <td><b>jangka_waktu_max</b> : {{ table1.parameterCicilan.jangka_waktu_max }}x</td>
                                <td><b>bunga</b> : {{ table1.parameterCicilan.bunga }}%</td>
                                <td></td>
                              </tr>
                              <tr>
                                <td><b>cicilan_default_1</b> : {{ table1.parameterCicilan.cicilan_default_1 }}x</td>
                                <td><b>cicilan_defaul_2</b> : {{ table1.parameterCicilan.cicilan_defaul_2 }}x</td>
                                <td><b>cicilan_default_3</b> : {{ table1.parameterCicilan.cicilan_default_3 }}x</td>
                              </tr>
                              <tr>
                                <td><b>datacicilan1</b> : {{ formatMoney(table1.parameterCicilan.datacicilan1) }}</td>
                                <td><b>datacicilan2</b> : {{ formatMoney(table1.parameterCicilan.datacicilan2) }}</td>
                                <td><b>datacicilan3 : </b>{{ formatMoney(table1.parameterCicilan.datacicilan3) }}</td>
                              </tr>
                            </tbody>
                          </v-simple-table>
                        </v-col>
                        <v-col cols="6" sm="2" md="2">
                          <v-text-field
                            type="number"
                            v-model="table1.itemEdit.lama_angsuran"
                            dense
                            outlined
                            hide-details
                            label="Lama Cicilan *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="2" md="2">
                          <v-text-field
                            readonly
                            v-model="table1.parameterCicilan.bunga"
                            dense
                            outlined
                            label="Bunga (%) *"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="2" sm="2">
                          <v-btn class="primary" small @click="getHitungCicilan()">Hitung</v-btn>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <v-text-field
                            readonly
                            v-model="table1.itemEdit.besar_angsuran"
                            outlined
                            dense
                            hide-details
                            label="Besar Cicilan *"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red" class="ml-4">
                            {{ formatMoney(table1.itemEdit.besar_angsuran) }}
                          </span>
                        </v-col>
                        <v-col cols="6" sm="3">
                          <v-text-field
                            readonly
                            dense
                            v-model="table1.itemEdit.bunga"
                            outlined
                            hide-details
                            label="Bunga Cicilan *"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red" class="ml-4">
                            {{ formatMoney(table1.itemEdit.bunga) }}
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            readonly
                            dense
                            outlined
                            hide-details
                            label="Total Cicilan *"
                            :value="parseFloat(table1.itemEdit.bunga) + parseFloat(table1.itemEdit.besar_angsuran)"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red" class="ml-4">
                            {{
                              formatMoney(
                                parseFloat(table1.itemEdit.bunga) + parseFloat(table1.itemEdit.besar_angsuran),
                              )
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveTrsBarang()"> Simpan </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-form class="ml-4">
              <v-row>
                <v-col cols="1" md="1">
                  <label for="firstname">Filter</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    label="ID trs/ Nama/kode anggota"
                    hide-details
                    outlined
                    dense
                    v-model="table1.search"
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                    label="Status"
                    :items="table1.statuss"
                    hide-details
                    outlined
                    dense
                    v-model="table1.status"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>

          <template v-slot:item.anggota="{ item }">
            {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.tgl_beli="{ item }">
            {{ formatDate(item.tgl_beli) }}
          </template>
          <template v-slot:item.harga="{ item }">
            <div style="text-align: right">{{ formatMoney(item.harga) }}</div>
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn x-small color="primary" class="mr-2" @click="openDialogEdit(item)" title="Lihat">
              <v-icon small> {{ icons.mdiEye }} </v-icon>
            </v-btn>
            <v-btn
              v-if="item.jenis_bayar == 'cicil' && item.status_transaksi == 'Belum lunas'"
              x-small
              color="secondary"
              class="mr-2"
              @click="openDialogCicilan(item)"
              title="Cicilan"
            >
              <v-icon small> {{ icons.mdiEye }} </v-icon> Cicilan
            </v-btn>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="getTable1()"> Reload </v-btn>
          </template>
        </v-data-table>
        <div class="text-center px-4 py-4 white">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="table1.length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table1.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="pageTable1" :length="table1.pageCount" :total-visible="3"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
      <v-dialog v-model="table1.dialogPilihanggota" max-width="900px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Pilih Anggota</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <pilih-anggota></pilih-anggota>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="table1.dialogPilihanggota = false"> Batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="table1.dialogPilihBarang" max-width="900px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Pilih Barang</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <pilih-barang></pilih-barang>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="table1.dialogPilihBarang = false"> Batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="table1.dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text v-if="table1.editedIndex != -1">
            <v-alert small dense type="info">
              {{ table1.itemSelected.status_transaksi }}
            </v-alert>
            <v-alert small dense type="warning">
              Jenis Bayar: {{ table1.itemSelected.jenis_bayar.toUpperCase() }}
            </v-alert>
            <v-row>
              <v-simple-table dense style="width: 100%">
                <tbody>
                  <tr>
                    <td><b>Kode Anggota</b> : {{ table1.itemSelected.kode_anggota }}</td>
                    <td><b>Nama</b> : {{ table1.itemSelected.nama_anggota }}</td>
                    <td>
                      <b>Wilayah</b> :
                      <span v-if="table1.itemSelected.wilayah_luar === '1'" style="color: red">{{
                        table1.itemSelected.wilayah
                      }}</span>
                      <span v-else>{{ table1.itemSelected.wilayah }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Tgl Lahir</b> : {{ formatDate(table1.itemSelected.tgl_lahir) }}</td>
                    <td><b>Divisi</b> : {{ table1.itemSelected.divisi }}</td>
                    <td><b>Jenis Upah</b> : {{ table1.itemSelected.jenis_upah }}</td>
                  </tr>
                  <tr>
                    <td><b>TGL Masuk</b> : {{ formatDate(table1.itemSelected.tgl_masuk) }}</td>
                    <td><b>Jabatan</b> : {{ table1.itemSelected.jabatan }}</td>
                    <td><b>Jumlah Upah : </b>{{ formatMoney(table1.itemSelected.jumlah_upah) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table dense style="width: 100%" class="mt-4">
                <tbody>
                  <tr>
                    <td><b>Nama Barang</b> : {{ table1.itemSelected.nama_barang }}</td>
                    <td><b>Detail</b> : {{ table1.itemSelected.detail }}</td>
                    <td><b>Harga</b> : {{ formatMoney(table1.itemSelected.harga) }}</td>
                  </tr>
                  <tr>
                    <td><b>Tgl Beli</b> : {{ formatDate(table1.itemSelected.tgl_beli) }}</td>
                    <td><b>Jenis Bayar : {{ table1.itemSelected.jenis_bayar.toUpperCase() }}</b></td>
                    <td>
                      <b>Status : {{ table1.itemSelected.status_transaksi }}</b>
                    </td>
                  </tr>
                  <tr v-if="table1.itemSelected.jenis_bayar == 'cicil'">
                    <td><b>Besar Angsur</b> : {{ formatMoney(table1.itemSelected.besar_angsuran) }}</td>
                    <td><b>Lama Angsur</b> : {{ table1.itemSelected.lama_angsuran }}</td>
                    <td><b>Bunga</b> : {{ formatMoney(table1.itemSelected.bunga) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-col cols="12" v-if="table1.itemSelected.status_transaksi == 'Pending'">
                <v-form class="multi-col-validation">
                  <v-row>
                    <v-col cols="12"
                      ><v-select
                        label="Pilih Status Transaksi"
                        :items="table1.statuss_foracc"
                        hide-details
                        outlined
                        dense
                        v-model="table1.itemEdit.status"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-simple-table
                v-else-if="
                  table1.itemSelected.status_transaksi == 'Belum lunas' ||
                  table1.itemSelected.status_transaksi == 'Lunas' ||
                  table1.itemSelected.status_transaksi == 'Siap ambil'
                "
                dense
              >
                <tbody>
                  <tr>
                    <td><b>TGL ACC</b> : {{ formatDate(table1.itemSelected.tgl_acc) }}</td>
                    <td></td>
                    <td></td>
                    <!-- <td><b>Petugas ACC</b> :</td> -->
                  </tr>
                </tbody>
              </v-simple-table>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" v-if="table1.itemSelected.status_transaksi == 'Pending'" @click="simpanTransaksi()">
              Simpan
            </v-btn>
            <v-btn color="secondary" @click="close()"> Batal </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="table2.dialog" max-width="1024px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Cicilan Barang</span>
          </v-card-title>
          <v-card-text>
            <div v-if="table1.editedIndex != -1">
              <v-alert small dense type="info">
                {{ table1.itemSelected.status_transaksi }}
              </v-alert>
              <v-simple-table dense style="width: 100%">
                <tbody>
                  <tr>
                    <td><b>Kode Anggota</b> : {{ table1.itemSelected.kode_anggota }}</td>
                    <td><b>Nama</b> : {{ table1.itemSelected.nama_anggota }}</td>
                    <td>
                      <b>Wilayah</b> :
                      <span v-if="table1.itemSelected.wilayah_luar === '1'" style="color: red">{{
                        table1.itemSelected.wilayah
                      }}</span>
                      <span v-else>{{ table1.itemSelected.wilayah }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Tgl Lahir</b> : {{ formatDate(table1.itemSelected.tgl_lahir) }}</td>
                    <td><b>Divisi</b> : {{ table1.itemSelected.divisi }}</td>
                    <td><b>Jenis Upah</b> : {{ table1.itemSelected.jenis_upah }}</td>
                  </tr>
                  <tr>
                    <td><b>TGL Masuk</b> : {{ formatDate(table1.itemSelected.tgl_masuk) }}</td>
                    <td><b>Jabatan</b> : {{ table1.itemSelected.jabatan }}</td>
                    <td><b>Jumlah Upah : </b>{{ formatMoney(table1.itemSelected.jumlah_upah) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-simple-table dense style="width: 100%" class="mt-4">
                <tbody>
                  <tr>
                    <td><b>Nama Barang</b> : {{ table1.itemSelected.nama_barang }}</td>
                    <td><b>Detail</b> : {{ table1.itemSelected.detail }}</td>
                    <td><b>Harga</b> : {{ formatMoney(table1.itemSelected.harga) }}</td>
                  </tr>
                  <tr>
                    <td><b>Tgl Beli</b> : {{ formatDate(table1.itemSelected.tgl_beli) }}</td>
                    <td><b>Jenis Bayar</b> : {{ table1.itemSelected.jenis_bayar.toUpperCase() }}</td>
                    <td>
                      <b>Status : {{ table1.itemSelected.status_transaksi }}</b>
                    </td>
                  </tr>
                  <tr v-if="table1.itemSelected.jenis_bayar == 'cicil'">
                    <td><b>Besar Angsur</b> : {{ formatMoney(table1.itemSelected.besar_angsuran) }}</td>
                    <td><b>Lama Angsur</b> : {{ table1.itemSelected.lama_angsuran }}</td>
                    <td><b>Bunga</b> : {{ formatMoney(table1.itemSelected.bunga) }}</td>
                  </tr>
                </tbody>
              </v-simple-table>

              <v-col cols="12" v-if="table1.itemSelected.status_transaksi == 'Pending'">
                <v-form class="multi-col-validation">
                  <v-row>
                    <v-col cols="12"
                      ><v-select
                        label="Pilih Status Transaksi"
                        :items="table1.statuss_foracc"
                        hide-details
                        outlined
                        dense
                        v-model="table1.itemEdit.status"
                        @change="optionChange()"
                      ></v-select
                    ></v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-simple-table
                v-else-if="
                  table1.itemSelected.status_transaksi == 'Belum lunas' ||
                  table1.itemSelected.status_transaksi == 'Lunas'
                "
                dense
              >
                <tbody>
                  <tr>
                    <td><b>TGL ACC</b> : {{ formatDate(table1.itemSelected.tgl_acc) }}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
            <v-data-table
              dense
              :headers="table2.headers"
              :items="table2.items"
              :loading="table2.loading"
              hide-default-footer
              :page.sync="pageTable2"
              :items-per-page="table2.itemsPerPage"
              class="elevation-1 mt-4"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-dialog v-model="table2.dialogBayar" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"> Bayar Cicilan </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Bayar Cicilan</span>
                      </v-card-title>
                      <v-card-text>
                        <v-form ref="form" lazy-validation>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field
                                label="Besar Cicilan"
                                readonly
                                :value="
                                  parseFloat(table1.itemSelected.besar_angsuran) + parseFloat(table1.itemSelected.bunga)
                                "
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" style="font-weight: bold; padding-top: 20px; color: red">
                              {{
                                formatMoney(
                                  parseFloat(table1.itemSelected.besar_angsuran) +
                                    parseFloat(table1.itemSelected.bunga),
                                )
                              }}
                            </v-col>
                            <!-- <v-col cols="12" md="6">
                              <v-text-field
                                label="Denda Cicilan"
                                v-model="table2.table1.itemEdit.denda"
                                outlined
                                dense
                                hide-details
                              ></v-text-field>
                            </v-col> -->
                          </v-row>
                        </v-form>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="saveBayarAngsuran()"> Simpan </v-btn>
                        <v-btn color="secondary" @click="closeBayarAngsuran()"> Batal </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.created_at="{ item }">
                {{ formatDate(item.created_at) }}
              </template>
              <template v-slot:item.angsuran_ke="{ item }">
                {{ item.angsuran_ke }}
              </template>
              <template v-slot:item.besar_angsuran="{ item }">
                {{ formatMoney(item.besar_angsuran) }}
              </template>
              <template v-slot:item.denda="{ item }">
                {{ formatMoney(item.denda) }}
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.name }}
              </template>
              <template v-slot:item.aksi="{ item }">
                <!-- <v-btn color="primary" small @click="openDialogIuranEdit(item)"> Edit Tanggal </v-btn> -->
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" small @click="getTable2()"> Reload </v-btn>
              </template>
            </v-data-table>
            <div class="text-center px-4 py-4">
              <v-row>
                <v-col cols="3">
                  <v-select
                    label="Jumlah Per Halaman"
                    :items="table2.length_menus"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="table2.itemsPerPage"
                    @change="optiontable2Change()"
                  ></v-select>
                </v-col>
                <v-col cols="9">
                  <v-pagination v-model="pageTable2" :length="table2.pageCount" :total-visible="3"></v-pagination>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn color="secondary" @click="closeDialogIuran()"> Keluar </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil, mdiDelete, mdiEye } from '@mdi/js'
import { reactive } from '@vue/composition-api'
import PilihAnggota from '../pinjaman/pinjaman/PilihAnggota.vue'
import PilihBarang from './barang/PilihBarang.vue'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  components: {
    PilihAnggota,
    PilihBarang,
  },
  setup() {
    const table1 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Anggota', value: 'anggota', sortable: false },
        { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
        { text: 'Tgl Beli', value: 'tgl_beli', sortable: false },
        { text: 'Nama Barang', value: 'nama_barang', sortable: false },
        { text: 'Harga Barang', value: 'harga', sortable: false, align: 'right' },
        { text: 'X Barang', value: 'qty', sortable: false },
        { text: 'Status', value: 'status_transaksi', sortable: false },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      status: 'SEMUA',
      length_menus: [10, 25, 50],
      statuss: ['Belum lunas', 'Lunas', 'Pending', 'Tolak', 'Siap ambil', 'SEMUA'],
      statuss_foracc: ['TERIMA', 'TOLAK'],
      dialog: false,
      dialogCU: false,
      dialogPilihanggota: false,
      dialogPilihBarang: false,
      editedIndex: -1,
      jenis_bayars: ['cicil', 'cash'],
      itemEdit: {
        status: '',
        id_barang: '',
        nama_barang: '',
        detail_barang: '',
        harga_temp: 0,
        harga: 0,
        jenis_bayar: 'cicil',
        besar_angsuran: 0,
        lama_angsuran: 0,
        bunga: '',
        id_anggota: '',
        kode_anggota: '',
        nama_anggota: '',
        jenis_upah_anggota: '',
        qty: '',
      },
      parameterCicilan: {
        // jangka_waktu_max: '20',
        // bunga: '2',
        // cicilan_default_1: '5',
        // cicilan_default_3: '20',
        // cicilan_defaul_2: '12',
        // datacicilan1: 3630000,
        // datacicilan2: 1705000,
        // datacicilan3: 1155000,
      },
      itemDefault: {
        status: '',
        id_barang: '',
        nama_barang: '',
        detail_barang: '',
        harga_temp: 0,
        harga: 0,
        jenis_bayar: 'cicil',
        besar_angsuran: 0,
        lama_angsuran: 0,
        bunga: '',
        id_anggota: '',
        kode_anggota: '',
        nama_anggota: '',
        jenis_upah_anggota: '',
        qty: '',
      },
      itemSelected: {},
    })
    const table2 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Tanggal Bayar', sortable: false, value: 'created_at' },
        { text: 'Cicilan Ke', value: 'angsuran_ke', sortable: false },
        { text: 'Besar Cicilan', value: 'besar_angsuran', sortable: false, align: 'right' },
        { text: 'Denda Cicilan', value: 'denda', sortable: false, align: 'right' },
        { text: 'User Entry', value: 'name', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      length_menus: [10, 25, 50],
      dialog: false,
      dialogBayar: false,
      editedIndex: -1,
      itemEdit: {
        angsuran_ke: 0,
        besar_angsuran: 0,
        denda: 0,
      },
      itemDefault: {
        status: '',
      },
    })
    function getTable1() {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable1)
      form.append('itemsPerPage', this.table1.itemsPerPage)
      form.append('search', this.table1.search)
      form.append('status', this.table1.status)
      axios
        .post(`${apiRoot}/api/Barang/getTransaksiBarangsTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.items = response.data.data
            this.table1.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    }
    function getTable2() {
      this.table2.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable2)
      form.append('itemsPerPage', this.table2.itemsPerPage)
      form.append('id_transaksi', this.table1.itemSelected.id)
      axios
        .post(`${apiRoot}/api/Cicilan/getAngsuranBarangTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table2.items = response.data.data
            this.table2.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table2.loading = false
        })
        .catch(error => {
          alert(error)
          this.table2.loading = false
        })
    }

    return {
      table1,
      table2,
      getTable1,
      getTable2,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
    }
  },
  data: () => ({
    pageTable1: 1,
    pageTable2: 1,
  }),
  computed: {
    formTitle() {
      return this.table1.editedIndex === -1 ? 'Detail Pengajuan' : 'Detail Pengajuan'
    },
    token() {
      return this.$store.getters.token
    },
    anggotaSelected() {
      return this.$store.getters.anggotaSelected
    },
    barangSelected() {
      return this.$store.getters.barangSelected
    },
  },
  watch: {
    pageTable1: {
      handler() {
        this.getTable1()
      },
      deep: true,
    },
    pageTable2: {
      handler() {
        this.getTable2()
      },
      deep: true,
    },
    anggotaSelected() {
      this.table1.itemEdit.id_anggota = this.anggotaSelected.id
      this.table1.itemEdit.kode_anggota = this.anggotaSelected.kode_anggota
      this.table1.itemEdit.nama_anggota = this.anggotaSelected.nama
      this.table1.itemEdit.jenis_upah_anggota = this.anggotaSelected.jenis_upah
      this.table1.dialogPilihanggota = false
    },
    barangSelected() {
      this.table1.itemEdit.id_barang = this.barangSelected.id
      this.table1.itemEdit.nama_barang = this.barangSelected.nama_barang
      this.table1.itemEdit.detail_barang = this.barangSelected.detail
      this.table1.itemEdit.harga_temp = this.barangSelected.harga_jual
      this.table1.dialogPilihBarang = false
    },
  },
  created() {
    this.getTable1()
  },
  methods: {
    optionChange() {
      this.pageTable1 = 1
      this.getTable1()
    },
    optiontable2Change() {
      // this.pageTable2 = 1
      this.getTable2()
    },
    openDialogTable1() {
      this.table1.itemEdit = Object.assign({}, this.table1.itemDefault)
      this.table1.parameterCicilan = {}
    },
    openDialogEdit(item) {
      this.table1.editedIndex = this.table1.items.indexOf(item)
      this.table1.itemSelected = Object.assign({}, item)
      this.table1.dialog = true
    },
    openDialogCicilan(item) {
      this.table1.editedIndex = this.table1.items.indexOf(item)
      this.table1.itemSelected = Object.assign({}, item)
      this.getTable2()
      this.table2.dialog = true
    },
    deleteItem(item) {
      this.table1.editedIndex = this.desserts.indexOf(item)
      this.table1.itemEdit = Object.assign({}, item)
      this.table1.dialogDelete = true
    },
    deleteItemConfirm() {
      this.desserts.splice(this.table1.editedIndex, 1)
      this.closeDelete()
    },
    close() {
      // this.$nextTick(() => {
      // })
      this.table1.itemEdit = Object.assign({}, this.table1.itemDefault)
      this.table1.editedIndex = -1
      this.table1.dialog = false
      this.table1.dialogCU = false
    },
    closeBayarAngsuran() {
      this.table2.dialogBayar = false
    },
    simpanTransaksi() {
      if (this.table1.editedIndex > -1) {
        this.$store.dispatch('loading', true)
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id_trs', this.table1.itemSelected.id)
        form.append('status', this.table1.itemEdit.status)
        axios
          .post(`${apiRoot}/api/Cicilan/saveTransaksiBeliBarang`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.getTable1()
              this.close()
            } else {
              alert(response.data.message)
            }
            this.$store.dispatch('loading', false)
          })
          .catch(error => {
            alert(error)
            this.$store.dispatch('loading', false)
          })
      }
    },
    saveBayarAngsuran() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_transaksi', this.table1.itemSelected.id)
      form.append(
        'besar_angsuran',
        parseFloat(this.table1.itemSelected.besar_angsuran) + parseFloat(this.table1.itemSelected.bunga),
      )
      axios
        .post(`${apiRoot}/api/Cicilan/saveBayarAngsuran`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getTable2()
            this.closeBayarAngsuran()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getCicilan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggota', this.table1.itemEdit.id_anggota)
      form.append('harga_jual', this.table1.itemEdit.harga)
      axios
        .post(`${apiRoot}/api/Cicilan/getParamter_cicilanJenis_upah2`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.parameterCicilan = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getHitungCicilan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_anggota', this.table1.itemEdit.id_anggota)
      form.append('harga_jual', this.table1.itemEdit.harga)
      form.append('lama_cicilan', this.table1.itemEdit.lama_angsuran)
      axios
        .post(`${apiRoot}/api/Cicilan/hitungcicilan2`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            // Object.assign(this.table1.parameterCicilan, response.data.data)
            this.table1.itemEdit.besar_angsuran = response.data.data.besar_angsuran
            this.table1.itemEdit.bunga = response.data.data.bunga
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    hitungTotal() {
      this.table1.itemEdit.harga = this.table1.itemEdit.qty * this.table1.itemEdit.harga_temp
    },
    cekJenisBayar() {
      if (this.table1.itemEdit.jenis_bayar === 'cash') {
        this.table1.parameterCicilan = {}
      }
    },
    saveTrsBarang() {
      let jenis_bayar = this.table1.itemEdit.jenis_bayar
      let besar_angsuran = 0
      let lama_angsuran = 0
      let bunga = 0
      if (jenis_bayar === 'cicil') {
        besar_angsuran = this.table1.itemEdit.besar_angsuran
        lama_angsuran = this.table1.itemEdit.lama_angsuran
        if (parseFloat(lama_angsuran) > parseFloat(this.table1.parameterCicilan.jangka_waktu_max)) {
          alert('Jangka waktu tidak boleh melebihi maksimal')
          return
        }
        bunga = this.table1.itemEdit.bunga
      }
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_barang', this.table1.itemEdit.id_barang)
      form.append('harga_jual', this.table1.itemEdit.harga)
      form.append('jenis_bayar', jenis_bayar)
      form.append('besar_angsuran', besar_angsuran)
      form.append('lama_angsuran', lama_angsuran)
      form.append('bunga', bunga)
      form.append('id_anggota', this.table1.itemEdit.id_anggota)
      form.append('qty', this.table1.itemEdit.qty)
      axios
        .post(`${apiRoot}/api/Cicilan/addBeliBarang2`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getTable1()
            this.close()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
  },
}
</script>
