var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Filter")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"nama barang","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searching()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"primary",on:{"click":function($event){return _vm.searching()}}},[_vm._v(" Cari ")])],1)]},proxy:true},{key:"item.harga",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.harga))+" ")]}},{key:"item.harga_jual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.harga_jual))+" ")]}},{key:"item.jumlah",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney(item.jumlah))+" ")]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"secondary",attrs:{"small":"","title":"Pilih"},on:{"click":function($event){return _vm.pilihItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowRightBoldBox)+" ")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getDataFromApi()}}},[_vm._v(" Reload ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }